import { jwtDecode } from "jwt-decode";

// Current rootURL is the backend version for gunicorn
// Change to http://127.0.0.1:5000 if running on python

// In production/deployment mode, since domain is the same for frontend and backend,
// rootURL is not needed, and hence, set it to an empty string.
export const rootURL =
    process.env.NODE_ENV === "development" ? "http://127.0.0.1:8000" : "";

export const startingTimestamp = 1546300800; // 2019-01-01 00:00:00

export function getPercentageChangeArray(array) {
    if (array.length > 0) {
        return array.map((val) => val / array[0] - 1);
    } else {
        return array;
    }
}

export function getResolution() {
    var data = {};
    let resolution;
    let radioButton = document.getElementsByName("flexRadio");
    for (let i = 0; i < radioButton.length; i++) {
        if (radioButton[i].checked) {
            resolution = radioButton[i].id;

            switch (resolution) {
                case "1M":
                    data["since"] = Date.now() / 1000 - 2592000;
                    data["granularity"] = 3600;
                    break;
                case "3M":
                    data["since"] = Date.now() / 1000 - 7776000;
                    data["granularity"] = 3600;
                    break;
                case "YTD":
                    // getFullYear() returns the 2023-01-01 08:00 instead of 00:00
                    data["since"] =
                        Date.parse(new Date().getFullYear()) / 1000 - 28800;
                    data["granularity"] = 86400;
                    break;
                default:
                    data["since"] = startingTimestamp;
                    data["granularity"] = 86400;
            }
        }
    }
    return data;
}

export function filterData(exchangeBalance, granularity) {
    let varName;
    let divisor;
    if (granularity === "hourly") {
        varName = "hourlyBalance";
        divisor = 3600;
    } else {
        varName = "dailyBalance";
        divisor = 86400;
    }
    const filteredData = exchangeBalance[varName].map((assetBalance) => {
        return assetBalance.filter((item) => {
            return (
                item.timestamp % divisor === 0
            );
        });
    });
    return filteredData;
}

export function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isTokenExpired(token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now().valueOf() / 1000;
    if (decodedToken.exp < currentTime) {
        return true;
    }
    return false;
}
