import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import ocra_icon from "../assets/ocra-logo.png";
import CustomizedMenus from "./CustomizedMenus";
import { googleLogout } from "@react-oauth/google";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DarkModeContext from "../context/DarkModeContext";

function Header({ setIsAuthenticated, entities}) {
    const { isDark, setIsDark } = useContext(DarkModeContext);

    return (
        <div>
            <div className="header">
                <Link to="/">
                    <img
                        className="ocra-icon"
                        src={ocra_icon}
                        alt="ocra-icon"
                    />
                </Link>
                <div className="navBar">
                    <Link to="/" className="tab">
                        INCIDENTS
                    </Link>
                    <CustomizedMenus entities={entities} />
                    <Link to="/docs" className="tab">
                        DOCS
                    </Link>
                    <div className="tab">
                        <IconButton
                            onClick={() => {
                                setIsDark(!isDark);
                            }}
                            sx={{
                                border: `1px solid`,
                            }}
                            className="tab"
                            size="small"
                            color="inherit"
                            >
                            {isDark ? <LightModeOutlined /> :  <DarkModeOutlined color="action" />}
                        </IconButton>
                    </div>
                    <div
                        className="logout"
                        onClick={() => {
                            googleLogout();
                            localStorage.clear();
                            setIsAuthenticated(false);
                        }}
                    >
                        LOGOUT
                    </div>
                </div>
            </div>
            <div className="divider" />
        </div>
    );
}

export default Header;
