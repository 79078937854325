import React, { createContext, useState, useEffect } from "react";

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
    const [isDark, setIsDark] = useState(() => {
        const savedPreference = localStorage.getItem("darkMode");
        return savedPreference ? JSON.parse(savedPreference) : false;
    });

    useEffect(() => {
        localStorage.setItem("darkMode", JSON.stringify(isDark));
    }, [isDark]);

    return (
        <DarkModeContext.Provider value={{ isDark, setIsDark }}>
            {children}
        </DarkModeContext.Provider>
    );
};

export default DarkModeContext;