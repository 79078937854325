import "./App.css";
import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Alert from "./components/Alert";
import ExchangeChart from "./components/ExchangeChart";
import Test from "./components/Test";
import ExchangeTable from "./components/ExchangeTable";
import Login from "./components/Login";
import Docs from "./components/Docs";
import { isTokenExpired } from "./utils/utils";
import { getEntities } from "./api/getEntities";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DarkModeContext from "./context/DarkModeContext";
import useCSSVariable from "./hooks/useCSSVariable";


function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { isDark } = useContext(DarkModeContext);

    const [entities, setEntities] = useState([]);
    const backgroundColor = useCSSVariable('color-background');
    const textColor = useCSSVariable('color-text');

    const theme = createTheme({
        palette: {
            mode: isDark ? 'dark' : 'light',
            background: {
                default: backgroundColor,
            },
            text: {
                primary: textColor,
            },
        },
    });
    
    useEffect(() => {
        if (
            !localStorage.getItem("idToken") ||
            isTokenExpired(localStorage.getItem("idToken"))
        ) {
            setIsAuthenticated(false);
        } else {
            setIsAuthenticated(true);
        }
    }, []);

    const getEntity = async () => {
        try {
            const response = await getEntities(setIsAuthenticated);
            setEntities(response);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            getEntity();
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App">
                    {!isAuthenticated ? (
                        <Login setIsAuthenticated={setIsAuthenticated} />
                    ) : (
                        <div>
                            <Header setIsAuthenticated={setIsAuthenticated} entities={entities}/>
                            <Routes>
                                <Route path="/" element={<Alert />} exact />
                                <Route path="/test" element={<Test />} exact />
                                <Route path="/docs" element={<Docs />} exact />
                                <Route
                                    path="/exchange"
                                    element={<ExchangeTable />}
                                />
                                <Route
                                    path="/chart/:exchange"
                                    element={<ExchangeChart entities={entities} backgroundColor={backgroundColor} textColor={textColor} />}
                                />
                            </Routes>
                            <Footer />
                        </div>
                    )}
                </div>
            </ThemeProvider>
        </Router>
    );
}

export default App;
