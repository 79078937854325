import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";

import App from "./App";
import { DarkModeProvider } from "./context/DarkModeContext";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const clientid = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const isProduction = process.env.REACT_APP_MODE === "PRODUCTION";

root.render(
    // Enable <React.StrictMode> in production
    // Disabled during development - renders components twice to detect problems (cause data fetching issues)
    isProduction ? (
        <React.StrictMode>
            <GoogleOAuthProvider clientId={clientid}>
                <DarkModeProvider>
                    <App />
                </DarkModeProvider>
            </GoogleOAuthProvider>
        </React.StrictMode>
    ) : (
        <GoogleOAuthProvider clientId={clientid}>
            <DarkModeProvider>
                <App />
            </DarkModeProvider>
        </GoogleOAuthProvider>
    )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
