import React from "react";
import "./Footer.css";
import crest_logo from "../assets/crest-logo.png";
import syzcrest_logo from "../assets/syzcrest-logo.png";

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-divider" />
            <div className="footer">
                <div className="footer-logo">
                    <img
                            className="footer"
                            src={crest_logo}
                            alt="crest-logo"
                    />
                    <img
                        className="footer"
                        src={syzcrest_logo}
                        alt="syzcrest-logo"
                    />
                </div>
            </div>
        </div>
    );
}

export default Footer;
