import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { useParams } from "react-router-dom";
import { getExchangeBalances } from "../api/getExchangeBalances";
import { Audio } from "react-loader-spinner";
import { filterData } from "../utils/utils";
import "./ExchangeChart.css";
import ChartOptions from "./ChartOptions";


function ExchangeChart({ entities=[], backgroundColor, textColor }) {
    const [exchangeBalance, setExchangeBalance] = useState({
        dailyBalance: [],
        hourlyBalance: [],
    });
    const assets = ["BTC", "ETH", "USDT", "USDC"];

    const [granularity, setGranularity] = useState("daily");
    const { exchange } = useParams();

    const yaxisType = "linear";
    const rangemode = "tozero";
    function calculateDomain() {
        const chartWidth = 0.84;
        const domainStart = (1 - chartWidth) / 2;
        const domainEnd = 1 - domainStart;
        return [domainStart, domainEnd];
    }

    const getInitialLayout = () => ({
        responsive: true,
        automargin: true,
        width: window.innerWidth * 0.8,
        height: Math.max(window.innerHeight - 310, 300),
        showlegend: true,
        dragmode: "pan",
        plot_bgcolor: backgroundColor,
        paper_bgcolor: backgroundColor,
        margin: {
            t: 30,
            b: 0,
            r: 50,
            l: 0,
            pad: 0,
        },
        xaxis: {
            type: "date",
            domain: calculateDomain(),
            rangeslider: { },
            tickfont: { color: textColor },
            titlefont: { color: textColor },
        },
        yaxis: {
            automargin: true,
            title: "USDC/USDT",
            tickformat: ",.3~s",
            tickfont: { color: textColor },
            titlefont: { color: textColor },
            showgrid: false,
            zeroline: false,
            rangemode: rangemode,
            fixedrange: false,
            type: yaxisType,
        },
        yaxis2: {
            title: {
                text: "BTC",
                standoff: 15,
            },
            tickformat: ",.3~s",
            tickfont: { color: "#F7931A" },
            titlefont: { color: "#F7931A" },
            side: "right",
            overlaying: "y",
            showgrid: false,
            zeroline: false,
            rangemode: rangemode,
            fixedrange: false,
            type: yaxisType,
        },
        yaxis3: {
            title: "ETH",
            minor_tick: "outside",
            tickformat: ",.3~s",
            tickfont: { color: "#808080" },
            titlefont: { color: "#808080" },
            side: "right",
            anchor: "free",
            position: 0.99,
            overlaying: "y",
            showgrid: false,
            zeroline: false,
            rangemode: rangemode,
            fixedrange: false,
            type: yaxisType,
        },
        legend: {
            x: 0.35,
            y: -0.34,
            orientation: "h",
            font: {
                color: textColor,
            },
        },
    });

    const [layout, setLayout] = useState(getInitialLayout());

    const getBalancesFromDB = async (exchange, asset, granularity) => {
        const daysForHourly = 180;
        const daysInSeconds = daysForHourly * 24 * 3600;
        try {
            var tempGranularity;
            var tempSince;
            if (granularity === "hourly") {
                tempGranularity = "1h";
                tempSince = Math.floor(Date.now() / 1000 - daysInSeconds);
            } else {
                tempGranularity = "1d";
                tempSince = null;
            }
            const response = await getExchangeBalances(
                exchange,
                asset,
                tempSince,
                tempGranularity
            );
            return response;
        } catch (e) {
            console.log(e);
        }
    };

    const updateBalances = async (reset = false) => {
        const promises = assets.map((asset) => {
            return getBalancesFromDB(exchange, asset, granularity);
        });

        try {
            const balanceResponses = await Promise.all(promises);
            if (granularity === "hourly") {
                const updatedBalance = {
                    dailyBalance: reset ? [] : exchangeBalance.dailyBalance,
                    hourlyBalance: [...balanceResponses],
                };
                setExchangeBalance(updatedBalance);
            } else {
                const updatedBalance = {
                    hourlyBalance: reset ? [] : exchangeBalance.hourlyBalance,
                    dailyBalance: [...balanceResponses],
                };
                setExchangeBalance(updatedBalance);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setExchangeBalance({ dailyBalance: [], hourlyBalance: [] });
        updateBalances(true);
        // eslint-disable-next-line
    }, [exchange]);

    useEffect(() => {
        const isDataAlreadyFetched = granularity === "hourly" 
            ? exchangeBalance.hourlyBalance.length > 0 
            : exchangeBalance.dailyBalance.length > 0;
        if (!isDataAlreadyFetched) {
            updateBalances();
        }
        // eslint-disable-next-line
    }, [granularity]);

    // Add this useEffect hook inside the ExchangeChart component
    useEffect(() => {
        const handleResize = () => {
            setLayout((prevLayout) => ({
                ...prevLayout,
                width: window.innerWidth * 0.8,
                height: Math.max(window.innerHeight - 310, 300),
            }));
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    var showChart = false;
    if (granularity === "hourly") {
        showChart = exchangeBalance && exchangeBalance.hourlyBalance.length > 0 && exchangeBalance.hourlyBalance[0].length > 0;
    } else {
        showChart = exchangeBalance && exchangeBalance.dailyBalance.length > 0 && exchangeBalance.dailyBalance[0].length > 0;
    }

    return (
        <div className="chartContainer">
            <div className="exchangeChart">
                <ChartOptions
                    granularity={granularity}
                    setGranularity={setGranularity}
                    selectedExchange={exchange}
                    exchanges={entities}
                />
                {showChart ? (
                    getChartData(
                        exchangeBalance,
                        granularity,
                        true,
                        "linear",
                        backgroundColor,
                        textColor,
                        layout
                    )

                ) : (
                    <div className="fetching">
                        <Audio
                            height="80"
                            width="80"
                            radius="9"
                            color="grey"
                            ariaLabel="loading"
                        />
                        <p>Fetching Chart...</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ExchangeChart;

function getChartData(exchangeBalance, granularity, isZerorange=true, scale="linear", backgroundColor="#FFF", textColor="#000", layout) {

    const filteredData = filterData(exchangeBalance, granularity);

    let data = filteredData.map((balance) => {
        if (balance.length > 0) {
            const balanceAmounts = balance.map(
                (balanceAmounts) => balanceAmounts["amount"]
            );
            // const percentageChangeBalanceAmounts =
            //     getPercentageChangeArray(balanceAmounts);
            const dates = balance.map(
                (balanceAmounts) => new Date(balanceAmounts["timestamp"] * 1000)
            );

            let asset = "";
            if (balance[0]["asset"]) {
                asset = balance[0]["asset"];
            }
            return {
                x: dates,
                y: balanceAmounts,
                name: asset,
            };
        } else {
            return null;
        }
    });

    data = data.filter((item) => item !== null);

    const classColors = {
        USDC: "#2775CA",
        USDT: "#26A17B",
        ETH: "#808080",
        BTC: "#F7931A",
    };

    const getYAxis = (name) => {
        switch (name) {
            case "BTC":
                return "y2";
            case "ETH":
                return "y3";
            default:
                return "y1";
        }
    };

    const coloredData = data.map((entry) => ({
        ...entry,
        marker: {
            color: entry && classColors[entry.name],
        },
        yaxis: getYAxis(entry.name),
        line: {
            width: 1.4,
        },
        responsive: true,
    }));

    var config = {responsive: true}

    return <Plot className="chart" data={coloredData} layout={layout} useResizeHandler={true} config={config} style={{width: "100%", height: "100%"}} />;
}
