import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAlert } from "../api/getAlert";
import { Audio } from "react-loader-spinner";
import "./Alert.css";
import { signAlert } from "../api/signAlert";
import { Capitalize } from "../utils/utils";

function Alert() {
    const [alerts, setAlerts] = useState([]);
    const [fetchStatus, setFetchStatus] = useState("Fetching Alerts");
    const [notes, setNotes] = useState("");

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("incident");

    const getAlerts = async () => {
        try {
            const response = await getAlert();
            setAlerts(response);
        } catch (e) {
            console.log(e);
            console.log("No unsigned alerts found.");
        }
    };

    const resolveAlert = async (incident, signer, notes) => {
        try {
            const response = await signAlert(incident, signer, notes);
            if (response["success"]) {
                const status = `Processing sign-off from ${signer}`;
                setFetchStatus(status);
                setAlerts([]);
                setNotes("");
                getAlerts();
            } else {
                setFetchStatus(
                    "Encountered error. See console for more information"
                );
                setNotes("");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleInputChange = (event) => {
        event.preventDefault();
        setNotes(event.target.value);
    };

    const handleSortOrder = (property) => {
        let newSortOrder;
        if (property !== orderBy) {
            newSortOrder = "asc";
        } else if (order === "asc") {
            newSortOrder = "desc";
        } else {
            newSortOrder = "asc";
        }
        setOrder(newSortOrder);
        setOrderBy(property);
    };

    useEffect(() => {
        if (alerts.length <= 0) {
            getAlerts();
        } else {
            const sortedAlerts = [...alerts].sort((a, b) => {
                if (
                    typeof a[orderBy] === "number" &&
                    typeof b[orderBy] === "number"
                ) {
                    return order === "asc"
                        ? a[orderBy] - b[orderBy]
                        : b[orderBy] - a[orderBy];
                } else if (
                    typeof a[orderBy] === "string" &&
                    typeof b[orderBy] === "string"
                ) {
                    return order === "asc"
                        ? a[orderBy].localeCompare(b[orderBy])
                        : b[orderBy].localeCompare(a[orderBy]);
                }
                // if the two values are not comparable, return the original array
                return a;
            });
            setAlerts(sortedAlerts);
        } // eslint-disable-next-line
    }, [order, orderBy]);

    const getAssetClass = (asset) => {
        switch (asset) {
            case "BTC":
                return "asset-btc";
            case "ETH":
                return "asset-eth";
            case "USDT":
                return "asset-usdt";
            default:
                return "asset-usdc";
        }
    };

    const calculateTimeToResolve = (incidentTimestamp, resolutionTimestamp) => {
        if (!resolutionTimestamp) return "N/A";
        if (!incidentTimestamp) return "N/A";
        const timeDifference = (resolutionTimestamp - incidentTimestamp) / 60;
        return Math.round(timeDifference) + " mins";
    };

    return (
        <div>
            {alerts && alerts.length > 0 ? (
                <table>
                    <thead id="alertTable">
                        <tr className="first-child">
                            <td><span className="header-text">TIMESTAMP</span></td>
                            <td
                                className="sort-field"
                                onClick={() => {
                                    handleSortOrder("incident");
                                }}
                            >
                                <span className="header-text">ID</span>
                            </td>
                            <td
                                className="sort-field"
                                onClick={() => {
                                    handleSortOrder("alert_type");
                                }}
                            >
                                <span className="header-text">TYPE</span>
                            </td>
                            <td
                                className="sort-field"
                                onClick={() => {
                                    handleSortOrder("exchange");
                                }}
                            >
                                <span className="header-text">COUNTERPARTY</span>
                            </td>
                            <td
                                className="sort-field"
                                onClick={() => {
                                    handleSortOrder("asset");
                                }}
                            >
                                <span className="header-text">ASSET</span>
                            </td>
                            <td><span className="header-text">RESOLUTION</span></td>
                            <td><span className="header-text">ANALYST</span></td>
                            <td><span className="header-text">TTR</span></td>
                        </tr>
                    </thead>
                    {alerts.map((cexAlert, index) => {
                        let rowName = "";
                        if (!cexAlert["signer"]) {
                            if (cexAlert["alert_type"] === "red") {
                                rowName = "redAlertRow";
                            } else {
                                rowName = "yellowAlertRow";
                            }
                        }
                        return (
                            <tbody
                                key={index}
                                className={
                                    !cexAlert["signer"]
                                        ? "red_alert"
                                        : undefined
                                }
                            >
                                <tr className={rowName}>
                                    <td>
                                        {new Date(
                                            cexAlert["incident_timestamp"] *
                                                1000
                                        ).toLocaleString("en-GB")}
                                    </td>
                                    <td>{cexAlert["incident"]}</td>
                                    <td>
                                        <span
                                            className={`${
                                                cexAlert["alert_type"] === "red"
                                                    ? "red-background"
                                                    : "yellow-background"
                                            } uppercase`}
                                        >
                                            {cexAlert["alert_type"]}
                                        </span>
                                    </td>
                                    <td>
                                        <Link
                                            to={
                                                "/chart/" + cexAlert["exchange"]
                                            }
                                        >
                                            {Capitalize(cexAlert["exchange"])}
                                        </Link>
                                    </td>
                                    <td>
                                        <span className={`asset ${getAssetClass(cexAlert["asset"])}`}>
                                            {cexAlert["asset"]} 
                                        </span>
                                    </td>
                                    {cexAlert["signer"] ? (
                                        <td>{cexAlert["notes"]}</td>
                                    ) : (
                                        <td>
                                            <textarea
                                                id={
                                                    "notes" +
                                                    cexAlert["incident"]
                                                }
                                                className="notesTextArea"
                                                placeholder='e.g. "false positive", because...'
                                                onChange={handleInputChange}
                                            ></textarea>
                                        </td>
                                    )}
                                    {cexAlert["signer"] ? (
                                        <td>{cexAlert["signer"]}</td>
                                    ) : (
                                        <td>{localStorage.getItem("user")}</td>
                                    )}
                                    {cexAlert["signer"] ? (
                                        <td>
                                            {calculateTimeToResolve(
                                                cexAlert["incident_timestamp"],
                                                cexAlert["resolution_timestamp"]
                                            )}
                                        </td>
                                    ) : (
                                        <td>
                                            <button
                                                className="resolve_button"
                                                onClick={() => {
                                                    resolveAlert(
                                                        cexAlert["incident"],
                                                        localStorage.getItem(
                                                            "user"
                                                        ),
                                                        notes
                                                    );
                                                }}
                                            >
                                                Resolve
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        );
                    })}
                </table>
            ) : (
                <div className="fetching">
                    <Audio
                        height="80"
                        width="80"
                        radius="9"
                        color="grey"
                        ariaLabel="loading"
                    />
                    <p>{fetchStatus}</p>
                </div>
            )}
        </div>
    );
}

export default Alert;
