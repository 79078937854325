import React from "react";
import "./ChartOptions.css";
import { Capitalize } from "../utils/utils";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


function ChartOptions({ granularity, setGranularity, selectedExchange, exchanges=[] }) {
    const navigate = useNavigate();

    const handleExchangeChange = (event) => {
        const newExchange = event.target.value;
        navigate(`/chart/${newExchange}`);
    };

    const handleGranularityChange = (event) => {
        setGranularity(event.target.value);
    };

    return (
        <div className="optionsContainer">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select
                    value={selectedExchange}
                    inputProps={{
                    name: 'exchange',
                    id: 'exchange-select',
                    }}
                    onChange={handleExchangeChange}
                >
                    {exchanges.map((exchange) => (
                        <MenuItem key={exchange} value={exchange}>{Capitalize(exchange)}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }} >
                <Select
                    value={granularity}
                    inputProps={{
                    name: 'resolution',
                    id: 'resolution-select',
                    }}
                    onChange={handleGranularityChange}
                >
                    <MenuItem value={"daily"}>Daily</MenuItem>
                    <MenuItem value={"hourly"}>Hourly</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

export default ChartOptions;