import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAlert } from "../api/getAlert";
import { Audio } from "react-loader-spinner";
import "./Alert.css";
import { signAlert } from "../api/signAlert";
import { TableSortLabel } from "@mui/material";

function Test() {
    const [alerts, setAlerts] = useState([]);
    const [fetchStatus, setFetchStatus] = useState("Fetching Alerts");
    const [notes, setNotes] = useState("");

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("incident");

    const getAlerts = async () => {
        try {
            const response = await getAlert(false);
            setAlerts(response);
        } catch (e) {
            console.log(e);
            console.log("No unsigned alerts found.");
        }
    };

    const resolveAlert = async (incident, signer, notes) => {
        try {
            const response = await signAlert(incident, signer, notes);
            if (response["success"]) {
                const status = `Processing sign-off from ${signer}`;
                setFetchStatus(status);
                setAlerts([]);
                setNotes("");
                getAlerts();
            } else {
                setFetchStatus(
                    "Encountered error. See console for more information"
                );
                setNotes("");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleInputChange = (event) => {
        event.preventDefault();
        setNotes(event.target.value);
    };

    const handleSortOrder = (property) => {
        let newSortOrder;
        if (property !== orderBy) {
            newSortOrder = "asc";
        } else if (order === "asc") {
            newSortOrder = "desc";
        } else {
            newSortOrder = "asc";
        }
        setOrder(newSortOrder);
        setOrderBy(property);
    };


    useEffect(() => {
        getAlerts();
    }, []);

    const getAssetClass = (asset) => {
        switch (asset) {
            case "BTC":
                return "asset-btc";
            case "ETH":
                return "asset-eth";
            case "USDT":
                return "asset-usdt";
            default:
                return "asset-usdc";
        }
    };

    const calculateTimeToResolve = (incidentTimestamp, resolutionTimestamp) => {
        if (!resolutionTimestamp) return "N/A";
        if (!incidentTimestamp) return "N/A";
        const timeDifference = (resolutionTimestamp - incidentTimestamp) / 60;
        return Math.round(timeDifference) + " mins";
    };

    return (
        <div>
            {alerts.length > 0 ? (
                <table>
                    <thead id="alertTable">
                        <tr className="first-child">
                            <td>TIMESTAMP</td>
                            <td
                                className="sort-field"
                                onClick={() => {
                                    handleSortOrder("incident");
                                }}
                            >
                                ID
                                <TableSortLabel
                                    active={orderBy === "incident"}
                                    direction={order}
                                />
                            </td>
                            <td
                                className="sort-field"
                                onClick={() => {
                                    handleSortOrder("alert_type");
                                }}
                            >
                                TYPE
                                <TableSortLabel
                                    active={orderBy === "alert_type"}
                                    direction={order}
                                />
                            </td>
                            <td
                                className="sort-field"
                                onClick={() => {
                                    handleSortOrder("exchange");
                                }}
                            >
                                COUNTERPARTY
                                <TableSortLabel
                                    active={orderBy === "exchange"}
                                    direction={order}
                                />
                            </td>
                            <td
                                className="sort-field"
                                onClick={() => {
                                    handleSortOrder("asset");
                                }}
                            >
                                ASSET
                                <TableSortLabel
                                    active={orderBy === "asset"}
                                    direction={order}
                                />
                            </td>
                            <td>RESOLUTION</td>
                            <td>ANALYST</td>
                            <td>TTR</td>
                            <td>Test?</td>
                        </tr>
                    </thead>
                    {alerts.map((cexAlert, index) => {
                        let rowName = "";
                        if (!cexAlert["signer"]) {
                            if (cexAlert["alert_type"] === "red") {
                                rowName = "redAlertRow";
                            } else {
                                rowName = "yellowAlertRow";
                            }
                        }
                        return (
                                                    <tbody
                                                        key={index}
                                                        className={
                                                            !cexAlert["signer"]
                                                                ? "red_alert"
                                                                : undefined
                                                        }
                                                    >
                                                        <tr className={rowName}>
                                                            <td>
                                                                {new Date(
                                                                    cexAlert["incident_timestamp"] *
                                                                        1000
                                                                ).toLocaleString("en-GB")}
                                                            </td>
                                                            <td>{cexAlert["incident"]}</td>
                                                            <td>
                                                                <span
                                                                    className={`${
                                                                        cexAlert["alert_type"] === "red" ? "red-background" : "yellow-background"
                                                                    } uppercase`}
                                                                >
                                                                    {cexAlert["alert_type"]}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    to={
                                                                        "/chart/" + cexAlert["exchange"]
                                                                    }
                                                                >
                                                                    {cexAlert["exchange"]}
                                                                </Link>
                                                            </td>
                                                            <td>{cexAlert["asset"]}</td>
                                                            {cexAlert["signer"] ? (
                                                                <td>{cexAlert["notes"]}</td>
                                                            ) : (
                                                                <td>
                                                                    <textarea
                                                                        id={
                                                                            "notes" +
                                                                            cexAlert["incident"]
                                                                        }
                                                                        className="notesTextArea"
                                                                        placeholder='e.g. "false positive", because...'
                                                                        onChange={handleInputChange}
                                                                    ></textarea>
                                                                </td>
                                                            )}
                                                            {cexAlert["signer"] ? (
                                                                <td>{cexAlert["signer"]}</td>
                                                            ) : (
                                                                <td>
                                                                    {localStorage.getItem("user")}
                                                                </td>
                                                            )}
                                                            {cexAlert["signer"] ? (
                                                                <td>
                                                                    {calculateTimeToResolve(
                                                                        cexAlert["incident_timestamp"],
                                                                        cexAlert["resolution_timestamp"]
                                                                    )}
                                                                </td>
                                                            ) : (
                                                                <td>
                                                                    <button
                                                                        className="resolve_button"
                                                                        onClick={() => {
                                                                            resolveAlert(
                                                                                cexAlert["incident"],
                                                                                localStorage.getItem(
                                                                                    "user"
                                                                                ),
                                                                                notes
                                                                            );
                                                                        }}
                                                                    >
                                                                        Resolve
                                                                    </button>
                                                                </td>
                                                            )}
                                                            <td>{cexAlert["test_alert"]}</td>
                                                        </tr>
                                                    </tbody>
                                                );
                    })}
                </table>
            ) : (
                <div className="fetching">
                    <Audio
                        height="80"
                        width="80"
                        radius="9"
                        color="grey"
                        ariaLabel="loading"
                    />
                    <p>{fetchStatus}</p>
                </div>
            )}
        </div>
    );
}

export default Test;
